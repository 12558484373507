import React from 'react';
import './ViewRenderedOutput.css';

import { Button, Modal } from 'react-bootstrap';

const ViewRenderedOutput = ({ showModal, zipUrl, videoUri, setVideoUri, setShowModal }) => {
    const handleDownload = () => {
        const a = document.createElement('a');
        a.href = zipUrl;
        a.download = 'package.zip';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Clean up
        URL.revokeObjectURL(zipUrl);
        setShowModal(false);
    };

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Download Your Package</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {videoUri && (
                    <>
                        <video key={videoUri} src={videoUri} controls style={{ width: '100%' }} />
                        <p style={{ marginTop: '10px' }}>
                            <a href={videoUri} target="_blank" rel="noopener noreferrer">
                                Open Video in New Tab
                            </a>
                        </p>
                        <p>Video is rendering in background, refresh if stuck.</p>
                        <Button onClick={() => setVideoUri(videoUri + `?t=${new Date().getTime()}`)}>Refresh</Button>
                    </>
                )}

                <p>All of your assets are contained in a neat package, you can download the below.</p>
                <Button variant="primary" onClick={handleDownload}>
                    Download
                </Button>
            </Modal.Body>
        </Modal>
    );
};

export default ViewRenderedOutput;
