import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import Header from './components/Header';
import ProjectsView from './components/Projects/ProjectsView';
import StudioView from './components/Studio/StudioView';

import ProtectedRoute from './components/Login/ProtectedRoute';
import Login from './components/Login/Login';
import CallbackComponent from './components/Login/CallbackComponent';

import VoicesPage from './components/Voices/VoicesPage';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';

const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [userPicture, setUserPicture] = useState(null);

    useEffect(() => {
        const token = Cookies.get('access_token');
        const picture = Cookies.get('user_picture');
        if (token) {
            setIsLoggedIn(true);
            setUserPicture(picture);
        } else {
            setIsLoggedIn(false);
        }
    }, []);

    const handleLogin = picture => {
        setIsLoggedIn(true);
        setUserPicture(picture);
    };

    const handleLogout = () => {
        Cookies.remove('access_token');
        Cookies.remove('user_email');
        Cookies.remove('user_picture');
        setIsLoggedIn(false);
        setUserPicture(null);
    };

    return (
        <Router>
            <div className="App">
                <Helmet>
                    <script type="text/javascript">
                        {`
                            (function(c,l,a,r,i,t,y){
                                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                            })(window, document, "clarity", "script", "nx4cy33fbc");
                        `}
                    </script>
                </Helmet>
                <Header handleLogout={handleLogout} isLoggedIn={isLoggedIn} userPicture={userPicture} />
                <Routes>
                    <Route path="/" element={isLoggedIn === null ? null : isLoggedIn ? <Navigate to="/projects" /> : <Login handleLogin={handleLogin} />} />{' '}
                    <Route path="/login/callback" element={<CallbackComponent handleLogin={handleLogin} />} />
                    <Route element={<ProtectedRoute isLoggedIn={isLoggedIn} />}>
                        <Route path="/projects" element={<ProjectsView />} />
                        <Route path="/project/:id" element={<StudioView />} />
                        <Route path="/voices" element={<VoicesPage />} />
                    </Route>
                </Routes>
            </div>
        </Router>
    );
};

export default App;
