const MAIN_API_URL = process.env.REACT_APP_MAIN_API_URL;

/**
 * Call the API to parse the script for camera movements and general shot directions. Parses all scenes together
 * when <sceneNumber> is not provided. Parses specified scene when sceneNumber is provided.
 *
 * @param data JavaScript object containing the project
 * @param setError Function to display an error modal to user
 * @param sceneNumber
 * @returns {Promise<{scriptText: *, scriptJsonWithCameraShots: any}|*[]>}
 */
export const postProcessScript = async (data, setError, sceneNumber = null) => {
    const url = `${MAIN_API_URL}/parse_scene`;

    // Check if the requested sceneNumber exists

    if (sceneNumber !== null && (!data.script.scenes[sceneNumber] || !data.cameras[sceneNumber])) {
        setError(`Failed to parse scene ${sceneNumber}`);
        setTimeout(() => {
            setError('');
        }, 10000);
        return [];
    }
    const scenes_text = sceneNumber === null ? data.script.scenes.map(scene => scene.scene_text) : [data.script.scenes[sceneNumber].scene_text];
    const existing_parsed_dialogue = sceneNumber === null ? data.cameras : [data.cameras[sceneNumber]];
    // Check if dialogue and scene text lengths are the same

    if (scenes_text.length !== existing_parsed_dialogue.length) {
        setError(`Failed to parse scene - you must have existing dialogue parsed for all scenes.`);
        setTimeout(() => {
            setError('');
        }, 10000);
        return [];
    }

    try {
        const result = [];

        for (let i = 0; i < scenes_text.length; i++) {
            const request = {
                scene: scenes_text[i],
                dialogues: existing_parsed_dialogue[i],
            };

            console.log('REQUEST BODY', request);

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request),
            });

            if (!response.ok) {
                // Handle network errors or non-200 responses here
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const processedScript = await response.json();
            const scriptJsonWithCameraShots = processedScript.scene_items;

            result.push(scriptJsonWithCameraShots);
        }

        return result;
    } catch (error) {
        console.log(error);
        setError('Failed to create package. Please try again.');
        setTimeout(() => {
            setError('');
        }, 10000);
        return []; // Return an empty array in case of an error
    }
};

export const parseDialogueFromScript = async (scene, characters, setError) => {
    const url = `${MAIN_API_URL}/parse_dialog_lines`;
    const charactersNameList = characters.map(character => character.name);

    const request = {
        scene: scene,
        characters: charactersNameList,
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    } catch (error) {
        setError('Error parsing dialogue from script.');
        setTimeout(() => {
            setError('');
        }, 10000);
    }
};

export const generateAudioWithParsedDialogue = async (dialogues, characters, setError) => {
    const url = `${MAIN_API_URL}/generate_voice_tracks`;
    const characterToVoiceMap = {};

    characters.forEach(char => {
        if (char.name && char.voice_id) {
            characterToVoiceMap[char.name] = char.voice_id;
        }
    });

    const request = {
        dialogues: dialogues,
        characters: characterToVoiceMap,
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(request),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    } catch (error) {
        setError('Error generating dialogue from script.');
        setTimeout(() => {
            setError('');
        }, 10000);
    }
};
