import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './StudioView.css';

import TitleBar from '../TitleBar';
import SelectionBar from '../SelectionBar';

import StoryView from '../Story/StoryView';
import ScriptView from '../Script/ScriptView';
import CharactersView from '../Characters/CharactersView';
import SoundsView from '../Sounds/SoundsView';
import SetsView from '../Sets/SetsView';
import CamerasView from '../Cameras/CamerasView';

import LoadingInformation from '../Modals/LoadingInformation';
import ErrorInformation from '../Modals/ErrorInformation';
import ViewRenderedOutput from '../Modals/ViewRenderedOutput';

import { generateBasicPrompt, generateAdvancedPrompt, text2script, generateStoryDescription, generateStoryImage, generate_additional_scene, generate_scene, generate_metadata } from '../../utils/text2script';
import { generate_first_scene } from '../../utils/text2script';
import { postProcessScript } from '../../utils/structureScript';
import { renderVideo } from '../../utils/renderVideo';
import { parseCharacters } from '../../utils/parseCharacters';
import { updateProject, updateProjectStory, updateProjectTitle, updateProjectScript, updateProjectCharacters, updateProjectSounds, updateProjectSets, updateProjectCameras, getProject } from '../../utils/projects';
import PickfordPackage from '../../utils/pickfordPackage';
import { Button } from 'react-bootstrap';

const useAutoSave = (updateFunction, logMessage, dependencies) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            console.log(logMessage);
            updateFunction();
        }, 5000); // 5 seconds

        return () => clearTimeout(timer); // Clear the timer if the dependencies change
    }, dependencies);
};

const fillMissingProjectData = projectData => {
    if (projectData.story === null) {
        projectData.story = {
            basic_prompt: '',
            scene_count: 1,
            advanced_prompt: {},
        };
    }
    if (projectData.script === null) {
        projectData.script = {
            scenes: [],
        };
    }
    projectData.characters = projectData.characters || [];
    projectData.sounds = projectData.sounds || [];
    projectData.sets = projectData.sets || [];
    projectData.cameras = projectData.cameras || [];
    return projectData;
};

const StudioView = () => {
    const { id } = useParams();

    const [activeTab, setActiveTab] = useState('Story');
    const [storyInputMode, setStoryInputMode] = useState('Basic');
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [error, setError] = useState('');
    const [videoUri, setVideoUri] = useState('');
    const [zipUrl, setZipUrl] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [saveStatus, setSaveStatus] = useState('Saved');

    const [data, setData] = useState({
        title: 'Studio Example',
        description: 'Description will be generated for you.',
        story: {
            basic_prompt: '',
            scene_count: 1,
            advanced_prompt: {
                genre: 'Action',
                tone: 5,
                plot: '',
                setting: '',
                cinematography: 'Low-key',
                theme: 'Existentialism',
                pace: 5,
                dialog_style: 'Modern English',
                ending: '',
            },
        },
        script: {
            scenes: [],
        },
        characters: [],
        sounds: [],
        sets: [],
        cameras: [[]],
    });

    useEffect(() => {
        const fetchProjectData = async () => {
            setLoading(true);
            setLoadingText('Loading project data...');
            try {
                const projectData = await getProject(id);
                setData(fillMissingProjectData(projectData));
            } catch (error) {
                setError(`Error fetching project: ${error.message}`);
            } finally {
                setLoading(false);
                setLoadingText('');
            }
        };

        fetchProjectData();
    }, [id]);

    // Unfortunately useEffect, with dependencies, needs to be called at top level so we can't iterate through these in a loop
    useAutoSave(
        () =>
            updateProjectStory({
                setError,
                setSaveStatus,

                story: data.story,
            }),
        'Auto-saving project story...',
        [data.story]
    );

    useAutoSave(
        () =>
            updateProjectTitle({
                setError,
                setSaveStatus,
                title: data.title,
            }),
        'Auto-saving project title...',
        [data.title]
    );

    useAutoSave(
        () =>
            updateProjectScript({
                setError,
                setSaveStatus,

                script: data.script,
            }),
        'Auto-saving project script...',
        [data.script]
    );

    useAutoSave(
        () =>
            updateProjectCharacters({
                setError,
                setSaveStatus,

                characters: data.characters,
            }),
        'Auto-saving project characters...',
        [data.characters]
    );

    // TODO: once we have this implemented in the database-api

    //useAutoSave(() => updateProjectSets({ setError, setSaveStatus, sets: data.sets }), 'Auto-saving project sets...', [data.sets]);

    useAutoSave(
        () =>
            updateProjectCameras({
                setError,
                setSaveStatus,

                cameras: data.cameras,
            }),
        'Auto-saving project cameras...',
        [JSON.stringify(data.cameras)]
    );

    useEffect(() => {
        if (error.length > 1) {
            setTimeout(() => {
                setError('');
            }, 10000);
        }
    }, [error]);

    const updateData = (view, newData) => {
        setData(prevData => ({
            ...prevData,
            [view]: newData,
        }));
        setSaveStatus('Unsaved');
    };

    const generateScript = async () => {
        if (!data || !data.story) {
            throw new Error('Data or story is not defined');
        }

        setLoading(true);
        const isBasicInput = storyInputMode === 'Basic';
        let localData = { ...data };

        try {
            setLoadingText('Generating Scene 1');
            const scene = await generate_scene(isBasicInput, true, localData);

            localData = {
                ...localData,
                script: { scenes: [{ scene_text: scene, scene_id: null }] },
            };
            updateData('script', localData.script);
            setActiveTab('Script');
        } catch (error) {
            setError(`Error generating script: ${error.message}`);
        } finally {
            setLoading(false);
            setLoadingText('');
        }

        if (data.story.scene_count > 1) {
            for (let i = 1; i < localData.story.scene_count; i++) {
                try {
                    setLoading(true);
                    setLoadingText(`Generating Scene ${i + 1}`);
                    const new_scene = await generate_scene(isBasicInput, false, localData);

                    localData = {
                        ...localData,
                        script: {
                            scenes: [...localData.script.scenes, { scene_text: new_scene, scene_id: null }],
                        },
                    };
                    updateData('script', localData.script);
                    setLoading(false);
                    setLoadingText('');
                } catch (error) {
                    setError(`Error generating script: ${error.message}`);
                }
            }
        }

        try {
            setLoading(true);
            setLoadingText('Generating Metadata');

            const { title, description } = await generate_metadata(localData);
            updateData('title', title);
            updateData('description', description);

            setLoading(false);
            setLoadingText('');
        } catch (error) {
            setError(`Error generating metadata: ${error.message}`);
        }
    };

    const parseCharactersFromScript = async () => {
        try {
            setLoading(true);
            setLoadingText('Parsing characters...');

            const script = data.script.scenes.map(scene => scene.scene_text).join('\n');
            const characters = await parseCharacters(script, setError);

            updateData('characters', characters);
        } finally {
            // Unset loading state
            setLoading(false);
            setLoadingText('');
            setActiveTab('Characters');
        }
    };

    const createStory = async () => {
        const packageInstance = new PickfordPackage();

        setLoading(true);
        setLoadingText('Creating package...');

        if (data.cameras.length === 0) {
            // Parse the shots if the script hasn't been manually parsed yet
            const { scriptJsonWithCameraShots, scriptText } = await postProcessScript(data, setError);
            packageInstance.addScriptText(scriptText);
            packageInstance.addScriptJson(JSON.stringify(scriptJsonWithCameraShots));
        } else {
            // Package the pre-parsed data
            packageInstance.addScriptJson(JSON.stringify({ scenes: data.cameras.flat(1) }));
            packageInstance.addScriptText(data.script.scenes.map(scene => scene.scene_text).join('\n'));
        }

        for (const character of data.characters) {
            if (character.rigged_file_url) {
                await packageInstance.addCharacter(character.rigged_file_url, character.name);
            }
        }

        const zipBlob = await packageInstance.compressPackage();
        const zipUrl = URL.createObjectURL(zipBlob);

        setLoadingText('Rendering story...');
        try {
            const videoUri = await renderVideo(zipBlob, setLoadingText, setError, setVideoUri);
            setVideoUri(videoUri + `?t=${new Date().getTime()}`); // Append timestamp to URI to force refresh
        } finally {
            setLoading(false);
            setLoadingText('');
        }

        setZipUrl(zipUrl); // If you want to use this URL for downloading or other purposes
        setShowModal(true);

        setLoading(false);
        setLoadingText('');
    };

    const renderActiveTab = () => {
        switch (activeTab) {
            case 'Story':
                return <StoryView storyInputMode={storyInputMode} setStoryInputMode={setStoryInputMode} data={data.story} updateData={newData => updateData('story', newData)} generateScript={generateScript} />;
            case 'Script':
                return <ScriptView data={data.script} updateData={newData => updateData('script', newData)} parseCharacters={parseCharactersFromScript} />;
            case 'Characters':
                return <CharactersView data={data.characters} updateData={newData => updateData('characters', newData)} setLoading={setLoading} setLoadingText={setLoadingText} setError={setError} />;
            case 'Dialogue':
                return <SoundsView data={data} setActiveTab={setActiveTab} setError={setError} updateData={newData => updateData('cameras', newData)} setLoading={setLoading} setLoadingText={setLoadingText} />;
            case 'Sets':
                return <SetsView />;
            case 'Cameras':
                return <CamerasView data={data} updateData={newData => updateData('cameras', newData)} setError={setError} />;
            default:
                return <StoryView storyInputMode={storyInputMode} setStoryInputMode={setStoryInputMode} data={data.story} updateData={newData => updateData('story', newData)} generateScript={generateScript} />;
        }
    };

    return (
        <>
            <TitleBar data={data.title} updateData={newData => updateData('title', newData)} createStory={createStory} saveStatus={saveStatus} />
            <div className={'bottom_pad'}>{renderActiveTab()}</div>
            <SelectionBar activeTab={activeTab} setActiveTab={setActiveTab} />
            {loading && <LoadingInformation text={loadingText} />}
            {error && <ErrorInformation errorText={error} />}
            <ViewRenderedOutput showModal={showModal} zipUrl={zipUrl} videoUri={videoUri} setVideoUri={setVideoUri} setShowModal={setShowModal} />
        </>
    );
};

export default StudioView;
